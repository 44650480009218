import React from 'react';
import { navigate } from 'gatsby';

import * as styles from './QuestionControls.module.scss';

import { useQuestionContext } from '../../../data/context/questionContext';
import { calculateArchtype } from '../../../modules/questions/questionManager';
import Button from '../../Button/Button';
import IconLeftChevron from '../../Icons/IconLeftChevron';
import IconRightChevron from '../../Icons/IconRightChevron';

const QuestionControls = (): JSX.Element => {
    const {
        isFirstQuestion,
        isLastQuestion,

        prevQuestion,
        nextQuestion,

        validateCurrentQuestion,
    } = useQuestionContext();


    const onNextClick = () => {
        const valid = validateCurrentQuestion();

        if (!valid) {
            return;
        }


        nextQuestion();
    };

    const onCompleteClick = () => {
        const valid = validateCurrentQuestion();

        if (!valid) {
            return;
        }

        if (isLastQuestion) {
            navigate(`/quiz/result/${calculateArchtype()}`);
        }
    };


    return (
        <div className={styles.questionControls}>
            {!isFirstQuestion && (
                <Button onClick={prevQuestion}>
                    <IconLeftChevron />
                    <span>Back</span>
                </Button>
            )}
            {!isLastQuestion && (
                <Button onClick={onNextClick}><span>Next</span>
                    <IconRightChevron />
                </Button>
            )}
            {isLastQuestion && (
                <Button onClick={onCompleteClick}><span>Complete</span>
                    <IconRightChevron />
                </Button>
            )}

        </div>
    );
};

export default QuestionControls;
