import React, { Fragment } from 'react';

import * as styles from './RadioInput.module.scss';

interface props {
    name?: string;
    value?: string | number;
    checked?: boolean;
    onChange?: (e)=> unknown;
}

const RadioInput = ({
    name, value, checked, onChange
}: props): JSX.Element => (
    <Fragment>
        <input
            name={name}
            type="radio"
            value={value}
            checked={checked}
            onChange={onChange}
            className={styles.radioInput}
        />
        <span className={styles.pseudoRadio} />
    </Fragment>

);

RadioInput.defaultProps = {
    name: null,
    value: null,
    checked: false,
    onChange: null
};

export default RadioInput;
