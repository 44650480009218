import React from 'react';
import BaseIcon, { BaseIconProps } from './BaseIcon';


const IconRightChevron = ({ ...rest }: BaseIconProps): JSX. Element => (
    <BaseIcon {...rest}>
        <path d="M31.426 24L19.404 11.98L16.576 14.808L25.776 24.008L16.576 33.194L19.404 36.022L31.426 24Z" />

    </BaseIcon>
);

export default IconRightChevron;
