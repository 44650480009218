import React from 'react';
import BaseIcon, { BaseIconProps } from './BaseIcon';


const IconLeftChevron = ({ ...rest }: BaseIconProps): JSX. Element => (
    <BaseIcon {...rest}>
        <path d="M16.576 24L28.596 36.02L31.424 33.192L22.224 23.992L31.424 14.792L28.596 11.98L16.576 24Z" />
    </BaseIcon>
);

export default IconLeftChevron;

