import * as React from 'react';
import Questions from '../components/Questions/Questions';
import Layout from '../layouts/Layout';

const QuizPage = (): JSX.Element => (
    <Layout>
        <Questions />
    </Layout>
);

export default QuizPage;
