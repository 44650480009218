import React, { createContext, useContext, useState } from 'react';
import get from 'lodash.get';
import { answerQuestion, getAnswers, getQuestions } from '../../modules/questions/questionManager';


const ERRORMESSAGE = 'Please ensure you have answered the question before moving on';

const questions = getQuestions();

interface QuestionContext {
        answers: Answers | null;
        updateAnswer: (id, answer)=> void;

        questions: Question[];
        currentQuestionIndex: number;

        isFirstQuestion: boolean;
        isLastQuestion: boolean;

        prevQuestion: ()=> any,
        nextQuestion: ()=> any,

        errorMessage: undefined | string;
        clearErrorMessge: ()=> void;
        validateCurrentQuestion: ()=> boolean;
}


const QuestionContext = createContext<QuestionContext>({ } as QuestionContext);
const { Provider } = QuestionContext;

interface QuestionProviderProps {
    children: React.ReactNode
}

export const QuestionProvider = ({ children }: QuestionProviderProps): JSX.Element => {
    const [answers, setAnswers] = useState(getAnswers()); /** Gets answers from the localstorage and prefills incase of a page refresh. */
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [errorMessage, setErrorMessage] = useState<QuestionContext['errorMessage']>();

    /** Gets the selected answer from the user, updates local storage and updates the current state. */
    const updateAnswer = (id, answer) => {
        setAnswers(answerQuestion(id, answer));
    };

    const isFirstQuestion = currentQuestionIndex === 0;
    const isLastQuestion = questions.length - 1 === currentQuestionIndex;
    const prevQuestion = () => {
        clearErrorMessge();
        setCurrentQuestionIndex((q) => q - 1);
    };
    const nextQuestion = () => setCurrentQuestionIndex((q) => q + 1);
    const clearErrorMessge = () => setErrorMessage(undefined);

    const validateCurrentQuestion = () => {
        const { id } = get(questions, currentQuestionIndex);

        // If answer exists in the answer array then it's all good
        const valid = !!(get(answers, id));

        if (!valid) setErrorMessage(ERRORMESSAGE); // if we're not valid then set an error message.
        if (valid && errorMessage) setErrorMessage(undefined); // if we're valid and we've got an error message then remove the error message

        return valid;
    };

    return (
        <Provider
            value={{
                answers,
                updateAnswer,

                questions,
                currentQuestionIndex,

                isFirstQuestion,
                isLastQuestion,

                prevQuestion,
                nextQuestion,

                errorMessage,
                clearErrorMessge,
                validateCurrentQuestion
            }}
        >
            {children}
        </Provider>
    );
};


export const useQuestionContext = (): QuestionContext => {
    const context = useContext(QuestionContext);

    if (context === undefined) {
        throw new Error('useQuestionContext must be used within a QuestionProvider');
    }

    return context;
};


// eslint-disable-next-line react/function-component-definition
export const withQuestionProvider = <T, >(ComponentToWrap: (props: T)=> JSX.Element): (props: T)=> JSX.Element => {
    const ReturnComponent = (props: T) => (
        <QuestionProvider>
            <ComponentToWrap {...props} />
        </QuestionProvider>
    );


    return ReturnComponent;
};
