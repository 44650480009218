import React, { useEffect } from 'react';
import get from 'lodash.get';


import { CSSTransition, SwitchTransition } from 'react-transition-group';

import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import { useQuestionContext } from '../../../data/context/questionContext';
import * as styles from './Question.module.scss';

import Helvetica from '../../Typography/Helvetica/Helvetica';
import RadioInput from '../../Form/Inputs/RadioInput/RadioInput';

interface Props {
    question: Question;
}

const Question = ({ question }: Props): JSX.Element => {
    const { answers, updateAnswer, errorMessage } = useQuestionContext();

    useEffect(() => {
        trackCustomEvent({
            category: 'Question Load',
            action: 'load',
            label: question.question,
            value: question.id,
        });
    }, [question]);

    return (
        <div className={styles.question}>
            <Helvetica tag="h3" typeset="heading">{question.question}</Helvetica>
            {question.options.map((option) => {
                const inputName = `question_${question.id.toString()}`;

                const usersAnswer = get(answers, question.id, {});

                return (
                    <Helvetica typeset="emphasis" tag="label" key={`question_${question.id.toString()}_${option.id}`} className="cursor-pointer gap-4 flex flex-row items-center">
                        <RadioInput
                            name={inputName}
                            value={option.id}
                            checked={usersAnswer.id === option.id}
                            onChange={() => {
                                updateAnswer(question.id, option);
                                trackCustomEvent({
                                    category: 'Answer Question',
                                    action: 'click',
                                    label: `${option.id} - ${option.name}`
                                });
                            }}
                        />

                        <span>{option.name}</span>
                    </Helvetica>
                );
            })}
            <SwitchTransition mode="out-in">
                <CSSTransition
                    key={errorMessage}
                    // onEntered={() => setAnimate(shouldAnimate)}
                    timeout={200}
                    classNames="fade"
                >
                    {errorMessage ? <div className={styles.questionError}>{errorMessage}</div> : <span />}
                </CSSTransition>
            </SwitchTransition>
        </div>
    );
};

export default Question;
