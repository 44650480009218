import React from 'react';

import { CSSTransition, SwitchTransition } from 'react-transition-group';
import get from 'lodash.get';
import * as styles from './Questions.module.scss';

import * as containStyles from '../../styles/contain.module.scss';

import { useQuestionContext, withQuestionProvider } from '../../data/context/questionContext';
import Question from './Question/Question';
import QuestionControls from './QuestionControls/QuestionControls';
import QuestionProgress from './QuestionProgress/QuestionProgress';


const Questions = withQuestionProvider(() => {
    const { questions, currentQuestionIndex } = useQuestionContext();

    const currentQuestion = questions[currentQuestionIndex];

    return (
        <div className={`${containStyles.contain} ${containStyles.containSmall} ${styles.questions}`}>
            <QuestionProgress />
            <div className={styles.questions}>
                <SwitchTransition mode="out-in">
                    <CSSTransition
                        key={get(currentQuestion, 'id')}
                        // onEntered={() => setAnimate(shouldAnimate)}
                        timeout={200}
                        classNames="fade"
                    >
                        {currentQuestion && <Question question={currentQuestion} />}
                    </CSSTransition>
                </SwitchTransition>
                <hr />
                <QuestionControls />
            </div>
        </div>
    );
});

export default Questions;
