import React from 'react';
import * as styles from './QuestionProgress.module.scss';
import { useQuestionContext } from '../../../data/context/questionContext';
import MillerDisplay from '../../Typography/MillerDisplay/MillerDisplay';

const QuestionProgress = (): JSX.Element => {
    const { questions, currentQuestionIndex } = useQuestionContext();

    return (
        <ul className={styles.questionProgress}>
            {questions.map((question, index) => {
                let className = `${styles.item}`;

                /** If we're on the current question  */
                if (index === currentQuestionIndex) {
                    className += ` ${styles.itemActive}`;
                }

                /** If we've done it */
                if (index < currentQuestionIndex) {
                    className += ` ${styles.itemComplete}`;
                }
                return <li key={question.id} className={className}><MillerDisplay typeset="figure" tag="span">{question.id}</MillerDisplay> </li>;
            })}

        </ul>
    );
};

export default QuestionProgress;
